import { isGuidedPreset, useGetSelectedWaxup } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT } from '../VisualConstants.util';
import { GuidedWaxupTimelineAndImagesSidebar } from '../components';
import { GuidedWaxupVideoWalkthrough } from '../components/GuidedWaxupVideoWalkthrough';
import { HistoricalWaxupModelViewerAndControls } from '../components/HistoricalWaxupModelViewerControls';
import { HistoricalWaxupPresetInfo } from '../components/HistoricalWaxupPresetInfo';
import { PresetTabsSidebar } from '../components/PresetTabSidebar';
import { RevisionHistoryTabs } from '../components/RevisionHistoryTabs';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { ContentContainer } from './ContentContainer';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ doesPreviousWaxupHavePresets: boolean; isSidebarExpanded?: boolean }>(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        height: GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    },
}));

const InnerContent: React.VFC<{ selectedDesignRevisionNotes?: string }> = ({ selectedDesignRevisionNotes }) => {
    const { selectedTab } = useGuidedWaxupContext();
    const guidedPreset = isGuidedPreset(selectedTab);

    if (selectedTab === LabsGqlGuidedWaxupPresetType.VideoWalkthrough) {
        return <GuidedWaxupVideoWalkthrough />;
    }

    return (
        <>
            <HistoricalWaxupModelViewerAndControls selectedDesignRevisionNotes={selectedDesignRevisionNotes} />
            {guidedPreset && <HistoricalWaxupPresetInfo />}
        </>
    );
};

export interface HistoricalWaxupScreensProps {
    selectedDesignRevisionNotes?: string;
    isApprovedWaxup?: boolean;
}

export const HistoricalWaxupDesktopScreen: React.VFC<HistoricalWaxupScreensProps> = ({
    selectedDesignRevisionNotes,
}) => {
    const selectedWaxup = useGetSelectedWaxup();
    // If a previously rejected waxup was submitted through either the legacy flow,
    // or through the ops portal, then there should be no presets on the rejection
    // If a previous waxup was approved, then we want to show the presets
    const doesPreviousWaxupHavePresets = !!selectedWaxup?.annotations;
    const presets = useGuidedWaxupSelector(s => s.presets);
    const { setSelectedTab, isSidebarExpanded } = useGuidedWaxupContext();
    const classes = useStyles({ doesPreviousWaxupHavePresets, isSidebarExpanded });

    // If we navigate to a historical screen for a rejection that wasn't submitted with presets, then
    // we want to only show them the general view to not confuse the doctor with the preset views since
    // they didn't submit the rejection with those in mind originally. We also hide the preset sidebar below
    // in this scenario
    React.useEffect(() => {
        if (!doesPreviousWaxupHavePresets) {
            setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
        }
    }, [doesPreviousWaxupHavePresets, setSelectedTab, presets]);

    return (
        <Grid container className={classes.root}>
            {doesPreviousWaxupHavePresets && <PresetTabsSidebar />}
            <ContentContainer doesPreviousWaxupHavePresets={doesPreviousWaxupHavePresets}>
                <RevisionHistoryTabs />
                <InnerContent selectedDesignRevisionNotes={selectedDesignRevisionNotes} />
            </ContentContainer>
            <GuidedWaxupTimelineAndImagesSidebar />
        </Grid>
    );
};
