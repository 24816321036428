import { useOrderRevisionItems } from '../../OrderDesignActions.graphql';
import { useGetSelectedWaxup, useTimelineAndImagesMenuItems } from '../GuidedWaxup.util';
import { PresetTabTopbar } from '../components';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import { LabsGqlDesignOrderDoctorReviewStatus, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { SimpleMenu } from '@orthly/ui';
import { Button, FlossPalette, Text, stylesFactory, IconButton, Grid, Icon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    closeButton: {
        padding: 8,
        minWidth: 0,
        background: FlossPalette.TAN,
        borderColor: 'transparent',
    },
    patientName: {
        marginLeft: 12,
    },
    vertIcon: {
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
}));

export const HistoricalWaxupsToolbarMobile: React.VFC = () => {
    const classes = useStyles();
    const {
        patientName,
        onBack,
        revisionId,
        setSelectedDesignRevisionId,
        order,
        designRevisionFragments,
        selectedDesignRevisionId,
        setSelectedTab,
        enableGuidedWaxupComparison,
        selectedDesignFragment,
    } = useGuidedWaxupContext();

    const timelineAndImagesMenuItems = useTimelineAndImagesMenuItems();

    const revisionItems = useOrderRevisionItems(
        setSelectedDesignRevisionId,
        'practitioner',
        order,
        designRevisionFragments,
        selectedDesignRevisionId,
    );

    const selectedWaxup = useGetSelectedWaxup();
    // If a previously rejected waxup was submitted through either the legacy flow,
    // or through the ops portal, then there should be no presets on the rejection.
    // If a previous waxup was approved, then we want to show the presets
    const doesPreviousWaxupHavePresets = !!selectedWaxup?.annotations;
    const presets = useGuidedWaxupSelector(s => s.presets);

    const selectedDesign = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, selectedDesignFragment);
    const isApprovedWaxup = selectedDesign?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;

    // If we navigate to a historical screen for a rejection that wasn't submitted with presets, then
    // we want to only show them the general view to not confuse the doctor with the preset views since
    // they didn't submit the rejection with those in mind originally. We also hide the preset topbar below
    // in this scenario
    React.useEffect(() => {
        if (!doesPreviousWaxupHavePresets) {
            setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
        }
    }, [doesPreviousWaxupHavePresets, setSelectedTab, presets]);

    return (
        <>
            <Grid item>
                <Button variant={'secondary'} className={classes.closeButton} onClick={() => onBack()}>
                    {enableGuidedWaxupComparison || revisionId !== undefined ? (
                        <Icon icon={'CloseIcon'} />
                    ) : (
                        <Icon icon={'ChevronLeft'} />
                    )}
                </Button>
            </Grid>
            <Grid item className={classes.patientName}>
                <Grid item>
                    <Text variant={'body2'} medium>
                        {`${patientName}'s Design`}
                    </Text>
                </Grid>
                <Grid item>
                    <Text variant={'caption'} color={isApprovedWaxup ? 'PRIMARY_FOREGROUND' : 'SECONDARY_FOREGROUND'}>
                        {isApprovedWaxup ? 'This is your approved design.' : `This is a previously rejected design.`}
                    </Text>
                </Grid>
            </Grid>
            <div style={{ flexGrow: 1 }} />
            <Grid item>
                <SimpleMenu
                    ButtonComponent={({ onClick }) => (
                        <IconButton onClick={onClick}>
                            <Icon icon={'MoreVertIcon'} className={classes.vertIcon} />
                        </IconButton>
                    )}
                    // With the comparison feature enabled, the revision items are displayed within the screen itself
                    // rather than within the menu
                    items={
                        !enableGuidedWaxupComparison
                            ? [...timelineAndImagesMenuItems, ...revisionItems]
                            : [...timelineAndImagesMenuItems]
                    }
                />
            </Grid>
            {doesPreviousWaxupHavePresets && <PresetTabTopbar />}
        </>
    );
};
